<template>
  <div>
    <v-row class="border-top mt-n4 mb-n6 mx-n6 pt-2">
      <v-col cols="8" :title="priceData.posOrderId">
        <a class="text--lighttext" @click="show = !show">
          <h3 class="text-uppercase font-weight-medium pb-3 pl-3">{{ title }}</h3>
          <span v-if="percentage && parseFloat(percentage) !== 100">
            &nbsp;({{ percentage }}%)
          </span>
        </a>
      </v-col>
      <v-col cols="4" class="text-right">
        <span class="ml-auto white--text pr-2" :class="statusColor + `--text`">
          {{ filteredStatus }}
        </span>
        <v-chip :color="statusColor" class="px-2 mr-2" x-small></v-chip>
      </v-col>
    </v-row>
    <div v-if="show" class="mt-4">
      <div v-if="previewItems.length < 1">No Items to show</div>
      <div v-else class="border-top pt-4">
        <div v-for="(item, i) in previewItems" :key="`price-item-` + i">
          <v-row>
            <v-col cols="9" style="font-size: 1.1em">
              {{ getItemTitle(item) }}
            </v-col>
            <v-col v-show="item.priceMoney.amount" class="text-right" cols="3">
              {{ (parseFloat(item.priceMoney.amount) / 100) | currency }}
            </v-col>
          </v-row>
          <v-row
            v-if="item.modifiers.length || item.note"
            class="mx-0 mt-1"
            style="width: 100%"
          >
            <v-list style="background: transparent; width: 100%" dense>
              <v-list-item
                v-for="(modifierList, j) in getModifierListsFromModifiers(
                  item.modifiers
                )"
                :key="`price-modifier-list` + j"
                dense
                :selectable="false"
                class="mt-n5 pt-2 pb-0 pr-0 pl-2 text--lighttext"
              >
                <v-list-item-content>
                  <v-container>
                    <v-row v-if="modifierList.name">
                      <v-col class="pa-1 pl-0">
                        <v-list-item-title>
                          {{ modifierList.name }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(modifier, k) in modifierList.modifiers"
                      :key="`price-modifier` + k"
                    >
                      <v-col class="py-1">
                        <v-list-item-title>
                          <span v-if="modifier.quantity && modifier.quantity > 1">
                            {{ modifier.quantity }} x
                          </span>
                          {{ modifier.name }}
                        </v-list-item-title>
                      </v-col>
                      <v-col
                        v-show="modifier.priceMoney.amount"
                        class="pa-1 pr-0 text-right"
                      >
                        <v-list-item-title>
                          <span v-if="parseFloat(modifier.priceMoney.amount) > 0">
                            +
                          </span>
                          <span v-else>&minus;</span>
                          {{
                            Math.abs(
                              parseFloat(
                                modifier.priceMoney.amount *
                                  (parseFloat(modifier.quantity) || 1)
                              ) / 100
                            ) | currency
                          }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="item.note"
                dense
                :selectable="false"
                class="mt-n5 pt-2 pb-0 pr-0 pl-2 text--lighttext"
              >
                <v-list-item-content class="d-block">
                  <span class="text--highlight">NOTE: {{ item.note }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </div>
        <div v-if="priceData" class="border-top pt-4 mt-4">
          <v-row v-if="priceData.bagFee">
            <v-col class="pb-0">
              <v-list-item-subtitle>BAG FEE</v-list-item-subtitle>
            </v-col>
            <v-col class="text-right pb-0">
              {{ (parseFloat(priceData.bagFee) / 100) | currency }}
            </v-col>
          </v-row>
          <v-row v-if="priceData.discount">
            <v-col class="pb-0">
              <v-list-item-subtitle>DISCOUNTS</v-list-item-subtitle>
            </v-col>
            <v-col class="text-right pb-0">
              {{ (parseFloat(priceData.discount) / -100) | currency }}
            </v-col>
          </v-row>
          <v-row v-if="priceData.discount && coupon" class="mt-n6">
            <v-col class="py-0">
              <v-list-item-subtitle>
                <span v-if="coupon" style="font-size: 0.8em">
                  <br />
                  {{ coupon.name }} ({{ coupon.couponCode }})
                </span>
              </v-list-item-subtitle>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-list-item-subtitle>SUBTOTAL</v-list-item-subtitle>
            </v-col>
            <v-col class="text-right pb-0">
              {{ (parseFloat(priceData.subTotal) / 100) | currency }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-list-item-subtitle>TAXES</v-list-item-subtitle>
            </v-col>
            <v-col class="text-right pb-0">
              {{
                (parseFloat(priceData.totalTaxes || priceData.taxes || 0) / 100)
                  | currency
              }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-list-item-subtitle>TIPS</v-list-item-subtitle>
            </v-col>
            <v-col class="text-right pb-0">
              {{ (parseFloat(priceData.tip) / 100) | currency }}
            </v-col>
          </v-row>
          <v-row class="border-top mt-6 mx-0">
            <v-col class="px-0">
              <v-list-item-subtitle>TOTAL</v-list-item-subtitle>
            </v-col>
            <v-col class="text-right px-0">
              {{ (parseFloat(priceData.grossTotal) / 100) | currency }}
            </v-col>
          </v-row>
          <v-row
            v-for="refund in refunds"
            :key="`refund-${refund.id}-entry`"
            class="mt-0"
          >
            <v-col>
              <v-list-item-subtitle class="red--text">
                {{ getRefundDescription(refund) }}
              </v-list-item-subtitle>
            </v-col>
            <v-col class="text-right red--text">
              {{ getRefundAmount(refund) | currency }}
            </v-col>
          </v-row>
          <v-row v-if="refunds && refunds.length" class="border-top">
            <v-col>
              <v-list-item-subtitle>NET TOTAL</v-list-item-subtitle>
            </v-col>
            <v-col class="text-right">
              {{ netTotal | currency }}
            </v-col>
          </v-row>
        </div>
        <div v-else>Price data unavailable.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  name: "restaurant-order-expandable-panel",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    percentage: {
      type: [String, Number],
      required: false,
      default: "",
    },
    status: {
      type: String,
      required: false,
      default: "",
    },
    previewItems: {
      type: Array,
      required: true,
    },
    priceData: {
      type: Object,
      required: false,
      default: () => {},
    },
    coupon: {
      type: Object,
      required: false,
      default: () => {},
    },
    refunds: {
      type: Array,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName"]),
    ...mapState(["modifierItems", "modifierLists", "items", "menuItems"]),
    filteredStatus() {
      if (this.refunds?.length) {
        return " Refunded"
      }
      if (this.status) {
        return " " + this.status
      } else {
        return ""
      }
    },
    statusColor() {
      const status = this.status.toLowerCase()
      if (this.refunds?.length) {
        return "red"
      } else if (
        status == "pending" ||
        status == "ready for pickup" ||
        status == "in progress"
      ) {
        return "orange"
      } else if (status == "completed") {
        return "green"
      } else if (status == "cancelled") {
        return "red"
      } else {
        return "grey"
      }
    },
    netTotal() {
      const orderNetTotal = parseFloat(this.priceData?.grossTotal) / 100
      const refundsNetTotal = this.refunds.reduce(
        (acc, refund) => acc + this.getRefundAmount(refund),
        0
      )
      return orderNetTotal + refundsNetTotal
    },
  },
  methods: {
    getItemName(ID) {
      let item = {}
      if (ID.charAt(ID.length - 1) == "+") {
        item = this.menuItems.find(item => item.id == ID.substring(0, ID.length - 1))
      } else {
        item = this.items.find(item => item.id == ID)
      }
      return item ? item.name : "N/A"
    },
    getOrderItemRestaurant(orderItem) {
      if (orderItem.restaurantId) {
        return this.$store.getters.getRestaurantName(orderItem.restaurantId)
      } else {
        return " Restaurant Not Found"
      }
    },
    getItemTitle(item) {
      let title = (item.quantity ? item.quantity : item.qty) + " "
      if (item.name) {
        title += item.name
      } else {
        title += this.getItemName(item.itemID ? item.itemID : item.id + "+")
      }
      return title
    },
    getRefundDescription(refund) {
      const type = refund.partialRefund ? "Partial" : "Full"
      const dateString = refund.refundDate.toDate().toISOString()
      const date = dateString.split("T")[0]
      const time = dateString.split("T")[1].split(".")[0]
      return `${type} Refund ${date} ${time}`.toUpperCase()
    },
    getRefundAmount(refund) {
      const thisRestaurantId = this.priceData.id
      const refundTotal = refund.restaurants?.find(
        restaurant => restaurant.id === thisRestaurantId
      )?.grossTotal
      return parseFloat(refundTotal || 0) / 100
    },
    getModifierListsFromModifiers(modifiers) {
      const modifierLists = []
      modifiers.forEach(mod => {
        const modifierList = this.modifierLists.find(
          item =>
            item.id == this.modifierItems.find(m => m.id === mod.id)?.modifierListId
        )
        if (modifierList) {
          let existingModifierListIndex = modifierLists.findIndex(
            item => item.id == modifierList.id
          )
          if (existingModifierListIndex > -1) {
            const existingModifierListCopy = JSON.parse(
              JSON.stringify(modifierLists[existingModifierListIndex])
            )
            const exisitngModifierIndex =
              existingModifierListCopy.modifiers.findIndex(m => m.id === mod.id)
            if (exisitngModifierIndex > -1) {
              const existingModifier =
                modifierLists[existingModifierListIndex].modifiers[
                  exisitngModifierIndex
                ]
              modifierLists[existingModifierListIndex].modifiers[
                exisitngModifierIndex
              ].quantity =
                parseInt(existingModifier.quantity) + (parseInt(mod.quantity) || 1)
            } else {
              if (!mod.quantity) {
                mod.quantity = 1
              }
              modifierLists[existingModifierListIndex].modifiers.push(mod)
            }
          } else {
            if (!mod.quantity) {
              mod.quantity = 1
            }
            modifierLists.push({
              id: modifierList.id,
              name: modifierList.name
                ?.replace(
                  /^(Do you need|Choose a|Choose any|Choose your|Add a|Add any|Add your|Add) /gi,
                  ""
                )
                .trim()
                .replace(/^(.)/, c => c.toUpperCase()),
              modifiers: [mod],
            })
          }
        }
      })
      return modifierLists
    },
  },
}
</script>
