<template>
  <v-expansion-panel v-if="loaded">
    <v-expansion-panel-header
      :color="expanded ? `light-background` : ``"
      @click="expanded = !expanded"
    >
      <span v-if="titleType === 'date-source'">
        <span class="text--lighttext">
          {{ moment(order.orderDate.toDate()).format("MMM DD, YYYY") }}
        </span>
        <v-chip
          v-if="order.channelData && order.channelData.appType"
          class="text--lighttext py-0 ml-2"
          small
        >
          {{ order.channelData.appType }}
        </v-chip>
      </span>
      <span v-else class="d-flex justify-space-between">
        <span class="d-flex">
          {{ getTitle(order) }}
          <span
            v-if="
              order.restaurants &&
              order.restaurants.length > 0 &&
              restaurants.length > 1
            "
          >
            <span
              style="border-right: solid 1px var(--v-lighttext-base)"
              class="mr-2"
            >
              &nbsp;
            </span>
            <span v-if="order.restaurants.length > 1" class="text--lighttext">
              {{ order.restaurants.length }} restaurants
            </span>
            <span v-else-if="order.restaurants.length" class="text--lighttext">
              {{ getRestaurantName(order.restaurants[0].id) }}
            </span>
          </span>
        </span>
        <span class="d-flex justify-end pr-3">
          {{ totalVisiblePrice | currency }}
        </span>
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content
      v-for="(restaurant, i) in visibleOrderRestaurants"
      :key="i"
      class="pt-4 panel-content"
    >
      <restaurant-order-expandable-panel
        :title="getRestaurantName(restaurant.id)"
        :percentage="
          getRestaurantPriceData(order, restaurant.id).percentOfTotalOrder
        "
        :priceData="restaurant"
        :refunds="orderRefunds"
        :coupon="getCoupon(order.appliedDiscount)"
        :status="getRestaurantStatus(order, restaurant.id)"
        :previewItems="getOrderItemsForRestaurant(order.orderItems, restaurant.id)"
      />
    </v-expansion-panel-content>
    <slot></slot>
  </v-expansion-panel>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import mixins from "@/AuthenticatedContent/Sales/mixins/getOrderRefunds.js"
import RestaurantOrderExpandablePanel from "./RestaurantOrderExpandablePanel.vue"

export default {
  name: "order-expandable-panels",
  components: {
    RestaurantOrderExpandablePanel,
  },
  mixins: [mixins],
  props: {
    order: {
      type: Object,
      required: true,
    },
    titleType: {
      type: String,
      default: "item-count-brands",
    },
  },
  data() {
    return {
      loaded: false,
      expanded: false,
      orderRefunds: [],
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName"]),
    ...mapState(["refunds", "coupons", "currentUserRole", "restaurants"]),
    totalVisiblePrice() {
      if (this.currentUserRole === "Admin") {
        return this.order.priceData && this.order.priceData.total / 100
      } else {
        return this.restaurants.reduce((total, r) => {
          const restaurant = this.order.restaurants.find(
            restaurant => restaurant.id == r.id
          )
          if (!restaurant) {
            return total
          }
          return total + restaurant?.grossTotal / 100
        }, 0)
      }
    },
    visibleOrderRestaurants() {
      if (this.currentUserRole === "Admin") {
        return this.order.restaurants
      } else {
        return this.order.restaurants.filter(r =>
          this.restaurants.find(restaurant => restaurant.id == r.id)
        )
      }
    },
  },
  watch: {
    refunds: {
      handler: async function () {
        this.loaded = false
        await this.setOrderRefunds()
        this.loaded = true
      },
      deep: true,
    },
  },
  async mounted() {
    await this.setOrderRefunds()
    this.loaded = true
  },
  methods: {
    async setOrderRefunds() {
      this.orderRefunds = await this.getOrderRefunds(this.order.id)
    },
    getOrderItemsForRestaurant(orderItems, restaurantId) {
      return orderItems
        ? Array.isArray(orderItems)
          ? orderItems.filter(item => item.restaurantId == restaurantId)
          : Object.values(orderItems).filter(
              item => item.restaurantId == restaurantId
            )
        : orderItems
    },
    getRestaurantStatus(item, restaurantId) {
      if (!Array.isArray(item.restaurants)) {
        return "Unknown"
      }
      let status = "Pending"
      // if (
      //   item.status == "Ready For Pickup" ||
      //   item.status == "Completed" ||
      //   item.status == "Refunded" ||
      //   item.status == "Cancelled"
      // ) {
      //   return item.status
      // }
      item.restaurants.forEach(restaurant => {
        if (restaurant.id == restaurantId && restaurant.status) {
          status = restaurant.status
        }
      })
      return status
    },
    getRestaurantPriceData(item, restaurantId) {
      if (typeof item.restaurants != Array) {
        return {
          total: 0,
          tax: 0,
          subtotal: 0,
          percentOfTotalOrder: 0,
        }
      }
      return item.restaurants?.find(restaurant => restaurant.id == restaurantId)
    },
    getCoupon(couponId) {
      return this.coupons.find(coupon => coupon.id == couponId)
    },
    getTitle(order) {
      const tooLong = this.getFullTitleLength(order) > 42
      if (tooLong) {
        return `${order.orderItems.length} items`
      } else {
        const filteredItems = order.orderItems.filter(i =>
          this.restaurants.find(r => r.id == i.restaurantId)
        )
        const firstItem = filteredItems[0]
        return `${firstItem.quantity > 1 ? `${firstItem.quantity} x ` : ``}${
          firstItem.name
        }${filteredItems.length > 1 ? ` +${filteredItems.length - 1} more` : ``}`
      }
    },
    getFullTitleLength(order) {
      let numChars = 0
      const filteredItems = order.orderItems.filter(i =>
        this.restaurants.find(r => r.id == i.restaurantId)
      )
      if (filteredItems.length) {
        numChars = filteredItems[0].name.length
        if (filteredItems[0].quantity > 1) {
          numChars += 4 // to count for the quantity prefix
        }
        if (filteredItems.length > 1) {
          numChars += 7 // to count for the " +x more"
        }
      }
      const filteredRestaurants = order.restaurants.filter(r =>
        this.restaurants.find(restaurant => restaurant.id == r.id)
      )
      if (this.restaurants.length > 1) {
        if (filteredRestaurants.length > 1) {
          numChars += 13 // to count for the "x restaurants"
        } else if (filteredRestaurants.length) {
          numChars += this.getRestaurantName(filteredRestaurants[0].id).length
        }
        numChars += 4 // to count for the separator
      }
      return numChars
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-content {
  * {
    color: var(--v-lighttext-base);
  }
}
.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}
</style>
