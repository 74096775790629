import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["refunds"]),
  },
  methods: {
    getOrderRefunds(orderId) {
      return this.refunds.filter(
        refund => refund.orderId === orderId && refund.status === "Completed"
      )
    },
  },
}
